(function ($, generic) {
  /**
   * Event listener to display the waitlist overlay.
   * Event trigger comes from module product_waitlist_v1.
   */
  $(document).on('product.waitlist.overlay', function (event, data) {
    Drupal.behaviors.productWaitlistOverlayV1.launch(data);
  });

  /*
   * GDPR tooltip.
   */
  var tooltipsterSettings = {
    animation: 'fade',
    arrow: false,
    contentAsHTML: true,
    interactive: true,
    onlyOne: true,
    position: 'top',
    speed: 500,
    theme: 'tooltipster-toofaced',
    timer: 5000,
    updateAnimation: false
  };

  Drupal.behaviors.productWaitlistOverlayV1 = {
    attach: function (context, settings) {
      var $waitlists = $('.js-waitlist-form', context);

      $waitlists.each(function (i, obj) {
        var $waitlist = $(obj);
        var $gdpr_link = $('.js-gdpr-signup-link', $waitlist);
        var $gdpr_content = $('.js-gdpr-signup-link-tooltip', $waitlist);

        $gdpr_link.tooltipster(tooltipsterSettings);
        $gdpr_link.tooltipster('content', $gdpr_content.html());
      });
    },

    /**
     * Drupal behavior method to handle the waitlist overlay display and functionality.
     * After the 'product.waitlist.overlay' event is fired, the product's data is passed to the launch method.
     * The product_waitlist_overlay inline template is then rendered and displayed using generic.overlay.launch.
     * Once rendered, the waitlist form submit rpc is initialized.
     * Form submission either results in a success message or errors displaying inline.
     * All messaging copy is handled within the waitlist module in the CMS.
     */
    launch: function (data) {
      var that = this;
      // Note that data is expected to be sku data.
      var productId = data['PRODUCT_ID'];
      var prodData = prodcat.data.getProduct(productId);
      var notify_me_settings = Drupal.settings.notify_me_settings || {};
      var override_key = 'out_of_stock';

      if (!!data['isComingSoon']) {
        override_key = 'coming_soon';
      }

      // Get the inv status override
      var override = {};

      if (notify_me_settings[override_key]) {
        override = notify_me_settings[override_key];
      }

      // Override messgaing
      if (_.isObject(override)) {
        _.each(override, function (value, key) {
          data[key] = value;
        });
      }

      // Allow override by product as well
      var product_override = null;

      if (!!data['isComingSoon']) {
        product_override = prodData['coming_soon_notify_me'];
      } else {
        product_override = prodData['out_of_stock_notify_me'];
      }

      // Override messgaing
      if (_.isObject(product_override)) {
        _.each(product_override, function (value, key) {
          data[key] = value;
        });
      }

      var rendered = site.template.get({
        name: 'product_waitlist_overlay',
        data: data
      });

      generic.overlay.launch({
        content: rendered,
        scrolling: false,
        cssStyle: {
          // Custom styles here.
          height: 'auto',
          width: 'auto',
          className: 'colorbox--waitlist'
        },
        onComplete: function (response) {
          var $overlayCont = $('.js-waitlist-container');

          Drupal.attachBehaviors($('#colorbox'));

          var $formCont = $('.js-waitlist-form-container', $overlayCont);
          var $form = $('.js-waitlist-form', $overlayCont);
          var $submit = $('.js-waitlist-submit', $overlayCont);
          var $email = $('.js-waitlist-email', $overlayCont);
          var $requestType = $('.js-waitlist-request-type', $overlayCont);
          var $skuBaseId = $('.js-waitlist-sku-base-id', $overlayCont);
          var $catBaseId = $('.js-waitlist-cat-base-id', $overlayCont);
          var $successCont = $('.js-waitlist-success', $overlayCont);
          var $errorList = $('.js-error-messages', $overlayCont);
          // Set request type.
          var requestType = data.INVENTORY_STATUS === that.statusIds.inventory.comingSoon ? 'CS' : 'BIS';

          $requestType.val(requestType);
          // Set sku_base_id and cat_base_id in the form.
          $skuBaseId.val(data.SKU_BASE_ID);
          var catBaseId = '';

          if (data && data.PARENT_CAT_ID) {
            var matchResult = data.PARENT_CAT_ID.match('[0-9]+');

            if (matchResult) {
              catBaseId = matchResult[0];
            }
          }
          $catBaseId.val(catBaseId);

          $.colorbox.resize();

          $form.on('submit', function (e) {
            e.preventDefault();

            var pObj = {};
            var params = $(this).serializeArray();

            for (var i = 0, len = params.length; i < len; i++) {
              pObj[params[i].name] = params[i].value;
            }

            generic.jsonrpc.fetch({
              method: 'form.get',
              params: [pObj],
              onSuccess: function (r) {
                $formCont.hide();
                $successCont.show();
                $.colorbox.resize();
              },
              onFailure: function (r) {
                var errorObj = r.getError();
                var errorArray = errorObj.data.messages;

                generic.showErrors(errorArray, $errorList);
                $.colorbox.resize();
              }
            });
          });
        }
      });
    },

    /**
     * Status ids used by the waitlist overlay; adjust accordingly.
     */
    statusIds: {
      inventory: {
        comingSoon: 3,
        soldOut: 7
      },
      lifeOfProduct: {
        basicReorder: 2
      }
    }
  };
})(jQuery, generic);
